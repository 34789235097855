import React from "react";
import BaseAccordion from "./base/Accordion";

interface ContentAccordionProps {
    items: any;
    hashID?: string | null,
    docLink?: string,
    grayTitle?: boolean;
}
const ContentAccordion: React.FC<ContentAccordionProps> = ({ items, grayTitle, hashID }) => {
    return(
        <div className="pb-5">
            <ul className="shadow-box text-sm border-t mx-5 border-gray-200">
                {items.map((accordion: any, i: any)     => {
                    return <BaseAccordion 
                        accordion={accordion} 
                        grayTitle={grayTitle} 
                        key={i} 
                        initialOpen={hashID && accordion.hashID === hashID}
                    />;
                })}
            </ul>
        </div>);
}

export default ContentAccordion;