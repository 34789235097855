import React, { useEffect } from 'react';
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import ContactForm from "../components/contact/ContactForm"; // Import the new ContactForm component

function Contact() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Contact"

    if (window.location.hash === "#contact") {
      const element = document.getElementById("contact");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

  }, []);

  return (
    <div className="Contact dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Contact"
                      buttons={[
                        {title: "Contact", url: "/contact#contact"}
                      ]}
        />

        {/* <!-- Blogs section --> */}
        {/*<section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white">*/}
        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 text-center dark:text-white"
          data-aos="fade-up">
            <h3 id="contact">
              <span className="text-header-gradient">Contact</span>!
            </h3>
            <p className="text-lg mb-8">
              Have questions, need help or anything else? <br />
              Don't hesitate to reach out!
            </p>

            <div id="contact-form" className="w-full max-w-4xl mx-auto p-6">
                <ContactForm />
            </div>
            <p className='text-sm italic'>
              Please note: We store your IP address when using this form for security purposes. <br />
              You can also reach us via LinkedIn.
            </p>
          </div>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default Contact;
