import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import './assets/css/framework.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Documentation from "./pages/Documentation";
import Blogs from "./pages/Blogs";
import WorkshopTranscript from "./pages/workshop/WorkshopTranscript";
import Workshop from "./pages/Workshop";

import Oops from "./pages/Oops";
import FrameworkIntroduction from "./pages/documentation/FrameworkIntroduction";
import Introduction from "./pages/documentation/Introduction";
import PromptEngineeringDocumentation from "./pages/documentation/PromptEngineering";
import FrameworkDocumentation from "./pages/documentation/FrameworkDocumentation";
import VisualFrameworkDocumentation from "./pages/documentation/VisualFrameworkDocumentation";
import PromptTechniquesDocumentation from "./pages/documentation/PromptTechniques";
import FrameworkCheatSheet from "./pages/documentation/FrameworkCheatSheet";
import Login from "./pages/dashboard/login";
import Register from "./pages/dashboard/register";
import Dashboard from "./pages/dashboard/dashboard";
import PromptArchitectureDocumentation from "./pages/documentation/PromptArchitecture";
import ReactGA from 'react-ga4';
import AdversarialPromptingDocumentation from "./pages/documentation/AdversarialPrompting";
import PromptInjectionDocumentation from "./pages/documentation/PromptInjections";
import PromptLeakingDocumentation from "./pages/documentation/PromptLeaking";
import PromptJailbreakDocumentation from "./pages/documentation/PromptJailbreak";
import Feedback from "./pages/Feedback";
import WorkshopIntroduction from "./pages/workshop/Introduction";
import WorkshopEventTimeline from "./pages/workshop/EventTimeline";
import QuizApp from "./pages/test/Quiz";
import Logout from "./pages/dashboard/logout";
import ContentPolicy from "./pages/ContentPolicy";
import Admin from "./pages/dashboard/admin";
import QuizTerms from "./pages/QuizTerms";
import AccountTerms from "./pages/AccountTerms";
import CaipTest from "./pages/CaipTest";
import AdminTests from "./pages/dashboard/admin/admin-tests";
import Profile from "./pages/dashboard/profile";
import Trainer from "./pages/dashboard/trainer";
import ShowCertificateOnline from "./pages/test/ShowCertificateOnline";
import Caip1IPE from "./pages/test-details/Caip1IPE";
import UserEdit from './pages/dashboard/admin/UserEdit';
import Tools from "./pages/Tools";
import AdminTools from "./pages/dashboard/admin/AdminTools";
import PromptLibrary from './pages/prompt-library/PromptLibrary';
import UseCases from './pages/documentation/UseCases';
import AIAct from './pages/dashboard/ai-act';
import ForgotPassword from './pages/dashboard/forgot-password';
import PasswordReset from './pages/dashboard/password-reset';
import CodingFrameworkDocumentation from './pages/documentation/CodingFramework';
import Applications from './pages/documentation/Applications';
import Contact from './pages/Contact';

AOS.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (localStorage.getItem('darkMode') === 'true') {
    document.getElementById('root')?.classList.add("dark");
    document.getElementById('root')?.classList.remove("light");
}else {
    document.getElementById('root')?.classList.add("light");
    document.getElementById('root')?.classList.remove("dark");
}

if (localStorage.getItem('cookies') === "0") {
    ReactGA.initialize('G-CX9LL68KRJ');
}

root.render(
    <React.StrictMode>
        <Toaster
            position="top-right"
            containerStyle={{
                top: '10%',
                maxWidth: '1280px',  
                margin: '0 auto',
                padding: '0 1.5rem',  
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%'
            }}
            toastOptions={{
                success: {
                    style: {
                        background: 'linear-gradient(to right, #4A8FF6, #38bdf8)',
                        color: '#fff',
                        padding: '16px',
                        borderRadius: '12px',
                        boxShadow: '0 8px 16px rgba(74, 143, 246, 0.2)',
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        maxWidth: '400px',
                        marginLeft: 'auto',  
                        marginRight: '20px'
                    },
                    icon: (
                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    ),
                },
                error: {
                    style: {
                        background: 'linear-gradient(to right, #ef4444, #f87171)',
                        color: '#fff',
                        padding: '16px',
                        borderRadius: '12px',
                        boxShadow: '0 8px 16px rgba(239, 68, 68, 0.2)',
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        maxWidth: '400px',
                        marginLeft: 'auto',
                        marginRight: '20px'
                    },
                    icon: (
                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    ),
                },
                loading: {
                    style: {
                        background: 'linear-gradient(to right, #64748b, #94a3b8)',
                        color: '#fff',
                        padding: '16px',
                        borderRadius: '12px',
                        boxShadow: '0 8px 16px rgba(100, 116, 139, 0.2)',
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        maxWidth: '400px',
                        marginLeft: 'auto',
                        marginRight: '20px'
                    },
                    icon: (
                        <svg className="w-6 h-6 text-white animate-spin" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                    ),
                },
                style: {
                    background: 'linear-gradient(to right, #1e293b, #334155)',
                    color: '#fff',
                    padding: '16px',
                    borderRadius: '12px',
                    boxShadow: '0 8px 16px rgba(30, 41, 59, 0.2)',
                    fontSize: '14px',
                    fontWeight: '500',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    maxWidth: '400px',
                    marginLeft: 'auto',
                    marginRight: '20px'
                },
                duration: 3000,
                className: 'animate-in fade-in slide-in-from-right duration-300',
            }}
        />
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/documentation" element={<Documentation/>}>
                    <Route index path={"framework/introduction"} element={<FrameworkIntroduction/>}/>
                    <Route index path={"large-language-models"} element={<Introduction/>}/>
                    <Route index element={<Navigate to="/documentation/framework/introduction" replace/>}/>
                    <Route path={"prompt-engineering"} element={<PromptEngineeringDocumentation/>}/>
                    <Route path={"framework"} element={<FrameworkDocumentation/>}/>
                    <Route path={"framework/visual"} element={<VisualFrameworkDocumentation/>}/>
                    <Route path={"prompt-techniques"} element={<PromptTechniquesDocumentation/>}/>
                    <Route path={"adversarial-prompting"} element={<AdversarialPromptingDocumentation/>}/>
                    <Route path={"adversarial-prompting/prompt-injections"} element={<PromptInjectionDocumentation/>}/>
                    <Route path={"adversarial-prompting/prompt-leaking"} element={<PromptLeakingDocumentation/>}/>
                    <Route path={"adversarial-prompting/prompt-jailbreak"} element={<PromptJailbreakDocumentation/>}/>
                    <Route path={"prompt-architecture"} element={<PromptArchitectureDocumentation/>}/>
                    <Route path={"use-cases"} element={<UseCases />}/>
                    <Route path={"applications"} element={<Applications />}/>
                    <Route path={"applications/coding"} element={<CodingFrameworkDocumentation/>}/>

                    <Route path="framework/cheatsheet" element={<FrameworkCheatSheet/>}/>
                </Route>
                
                <Route path={"/tools"} element={<Tools />}/>
                <Route path={"/tools/tool/:toolId/:toolName"} element={<Tools />}/>
                <Route path={"/tools/profile/:userName"} element={<Tools />}/>
                <Route path={"/tools/profile/:userName/tool/:toolId/:toolName"} element={<Tools />}/>

                <Route path="/workshop" element={<Workshop/>}>
                    <Route index element={<WorkshopIntroduction />}/>
                    <Route path={"transcript"} element={<WorkshopTranscript />}/>
                    <Route path={"event-timeline"} element={<WorkshopEventTimeline />}/>
                </Route>
    
                <Route path="/prompt-library" element={<Documentation/>}>
                    <Route index element={<PromptLibrary/>}/>
                </Route>
                
                <Route path="/blogs" element={<Blogs/>}/>
                <Route path="/content-policy" element={<ContentPolicy />}/>
                <Route path="/test/terms" element={<QuizTerms />}/>
                <Route path="/account/terms" element={<AccountTerms />}/>
                <Route path="/test/" element={<CaipTest />}/>
                <Route path="/test/caip-1" element={<Caip1IPE />}/>
                <Route path="/feedback" element={<Feedback />}/>
                <Route path="/contact" element={<Contact />}/>

                <Route path="/register" element={<Register/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/logout" element={<Logout />}/>
                <Route path="/forgot-password" element={<ForgotPassword />}/>
                <Route path="/password-reset/:token" element={<PasswordReset />}/>

                <Route path="/dashboard/overview" element={<Dashboard/>}/>
                <Route path="/dashboard/profile" element={<Profile />}/>
                <Route path="/dashboard/quiz" element={<QuizApp />}/>

                <Route path="/dashboard/trainer" element={<Trainer />}/>
                <Route path="/dashboard/ai-act" element={<AIAct />}/>

                <Route path="/dashboard/admin" element={<Admin />}/>
                <Route path="/dashboard/admin/test/questions" element={<AdminTests />}/>
                <Route path="/dashboard/admin/tools/" element={<AdminTools />}/>
                <Route path="/dashboard/admin/user/:userId" element={<UserEdit />} />

                <Route path={"/certificate/show/:id"} element={<ShowCertificateOnline />}/>

                {/* Redirects */}
                <Route path="/framework" element={<Navigate to="/documentation/framework" replace/>}/>
                <Route path="/framework/techniques" element={<Navigate to="/documentation/prompt-techniques" replace/>}/>
                <Route path="/documentation/prompt-injections"
                       element={<Navigate to="/documentation/adversarial-prompting" replace/>}/>
                <Route path="/framework/cheatsheet"
                       element={<Navigate to="/documentation/framework/cheatsheet" replace/>}/>

                <Route path="/dashboard" element={<Navigate to="/dashboard/overview" replace/>}/>

                <Route path={"*"} element={<Oops/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
