import React, { useState, useRef, useEffect } from 'react';
import axios from '../../libs/axios';
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setIsDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        return () => observer.disconnect();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (formData.message.length < 20) {
            toast.error('Message must be at least 20 characters long');
            return;
        }

        const recaptchaValue = recaptchaRef.current?.getValue();
        if (!recaptchaValue) {
            toast.error('Please verify that you are not a robot');
            return;
        }

        setLoading(true);
        const toastId = toast.loading('Sending message...');
        
        try {
            await axios.post('/public/contact/send', {
                ...formData,
                recaptchaToken: recaptchaValue
            });

            // Format the success message
            const successMessage = (
                <div className="space-y-2">
                    <div className="font-semibold mb-4">Message sent successfully! <span role="img" aria-label="party popper emoji">🎉</span></div>
                    <div className="text-sm space-y-1">
                        <div><span className="font-medium">Your details:</span> {formData.name} ({formData.email})</div>
                        <div><span className="font-medium mb-2">Subject:</span> {formData.subject}</div>
                        <div><span className="font-medium">Your message:</span></div>
                        <div className="text-gray-600 dark:text-gray-300 font-medium italic">
                            {formData.message.length > 100 
                                ? `${formData.message.substring(0, 100)}...` 
                                : formData.message}
                        </div>
                    </div>
                </div>
            );

            toast.success(successMessage, { 
                id: toastId,
                duration: 10000,
                style: {
                    minWidth: '300px',
                }
            });
            setFormData({ name: '', email: '', subject: '', message: '' });
            recaptchaRef.current?.reset();
        } catch (error) {
            toast.error('Failed to send message. Please try again.', { id: toastId });
        }
        setLoading(false);
    };

    const inputClasses = `peer w-full border-b-2 border-gray-300 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-gray-900 outline outline-0 transition-all placeholder-shown:border-gray-200 focus:border-[#4A8FF6] focus:outline-0 disabled:border-0 disabled:bg-gray-50 dark:text-white dark:border-gray-600 dark:focus:border-[#4A8FF6] dark:placeholder-shown:border-gray-500`;

    const autofillStyles = `
        .dark input:-webkit-autofill,
        .dark input:-webkit-autofill:hover,
        .dark input:-webkit-autofill:focus,
        .dark textarea:-webkit-autofill,
        .dark textarea:-webkit-autofill:hover,
        .dark textarea:-webkit-autofill:focus {
            -webkit-text-fill-color: #ffffff !important;
            -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
            transition: background-color 5000s ease-in-out 0s !important;
            background-color: transparent !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus {
            -webkit-text-fill-color: #111827 !important;
            -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
            transition: background-color 5000s ease-in-out 0s !important;
            background-color: transparent !important;
        }
    `;

    const recaptchaStyles = `
        .dark .g-recaptcha iframe {
            background-color: #1a1a1a !important;
        }
        .dark .grecaptcha-badge {
            filter: invert(0.85) hue-rotate(180deg);
        }
        .dark iframe[src*="recaptcha"] {
            filter: invert(0.85) hue-rotate(180deg);
        }
    `;

    return (
        <>
            <style>{autofillStyles}</style>
            <style>{recaptchaStyles}</style>
            <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Name Input */}
                    <div className="group relative transform transition-all duration-300 ease-in-out">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className={inputClasses}
                            placeholder=" "
                        />
                        <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-[#4A8FF6] after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#4A8FF6] peer-focus:after:scale-x-100 peer-focus:after:border-[#4A8FF6] peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 dark:text-gray-300 dark:peer-focus:text-[#4A8FF6]">
                            Name
                        </label>
                    </div>

                    {/* Email Input */}
                    <div className="group relative transform transition-all duration-300 ease-in-out">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className={inputClasses}
                            placeholder=" "
                        />
                        <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-[#4A8FF6] after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#4A8FF6] peer-focus:after:scale-x-100 peer-focus:after:border-[#4A8FF6] peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 dark:text-gray-300 dark:peer-focus:text-[#4A8FF6]">
                            Email
                        </label>
                    </div>
                </div>

                {/* Subject Input */}
                <div className="group relative transform transition-all duration-300 ease-in-out">
                    <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                        className={inputClasses}
                        placeholder=" "
                    />
                    <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-[#4A8FF6] after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#4A8FF6] peer-focus:after:scale-x-100 peer-focus:after:border-[#4A8FF6] peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 dark:text-gray-300 dark:peer-focus:text-[#4A8FF6]">
                        Subject
                    </label>
                </div>

                {/* Message Input */}
                <div className="group relative transform transition-all duration-300 ease-in-out">
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows={4}
                        className={`${inputClasses} resize-none`}
                        placeholder=" "
                    />
                    <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-[#4A8FF6] after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#4A8FF6] peer-focus:after:scale-x-100 peer-focus:after:border-[#4A8FF6] peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 dark:text-gray-300 dark:peer-focus:text-[#4A8FF6]">
                        Message
                    </label>
                    <div className={`text-xs mt-1 ${formData.message.length < 20 ? 'text-red-500' : 'text-gray-500 dark:text-gray-400'}`}>
                        {formData.message.length}/20 characters minimum
                    </div>
                </div>

                {/* reCAPTCHA */}
                <div className="flex justify-center mb-6">
                    <div className={`transform transition-all duration-300 rounded-lg overflow-hidden ${isDarkMode ? 'opacity-90 hover:opacity-100 dark:bg-[#1a1a1a]' : 'opacity-95 hover:opacity-100'} shadow-lg`}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={
                                window.location.hostname === 'localhost' 
                                    ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'  // Test key for localhost
                                    : '6LfwwNIqAAAAAJfdkkuSzZ9RDwa0hSoKxsBGEtjt'  // Production key
                            }
                            theme={isDarkMode ? "dark" : "light"}
                            size="normal"
                            className="transform transition-all duration-300 scale-100"
                        />
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                    <button
                        type="submit"
                        disabled={loading}
                        className="group relative inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-[#4A8FF6] to-blue-500 p-0.5 font-medium text-gray-900 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 group-hover:from-blue-600 group-hover:to-blue-500 dark:text-white dark:focus:ring-blue-800 transform transition-all duration-300 ease-in-out hover:scale-105"
                    >
                        <span className="relative rounded-md bg-white px-5 py-2.5 transition-all duration-300 ease-in-out group-hover:bg-opacity-0 dark:bg-gray-900">
                            {loading ? (
                                <div className="flex items-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Sending...
                                </div>
                            ) : (
                                'Send Message'
                            )}
                        </span>
                    </button>
                </div>
            </form>
        </>
    );
};

export default ContactForm;